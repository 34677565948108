<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-file-text"></i> 
                                    List Push Notification / {{$route.params.id ? 'Edit' : 'Tambah'}} Push Notification
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/notification">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Push Notification</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Judul notifikasi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Judul Notifikasi</label>
                                                            <input type="text" v-model="model.name" id="name" class="form-control"
                                                                placeholder="Masukkan judul notifikasi">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Deskripsi</label>
                                                            <textarea name="" id="" cols="30" rows="5" class="form-control" v-model="model.description" placeholder="Tulis deskripsi"></textarea>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Tipe Notifikasi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Tipe notifikasi</label>
                                                            <select name="" id="" v-model="model.type" class="form-control">
                                                                <option value="">Pilih Tipe</option>
                                                                <option value="Promo">Promo</option>
                                                                <option value="Informasi">Informasi</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Penerima notifikasi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Penerima Notifikasi</label>
                                                            <div class="container ml-0">
                                                                <div class="row mt-1">                                                        
                                                                    <input type="radio" value="1" name="semua" v-model="model.penerima" style="align-self: center">
                                                                    <label for="semua" class="ml-1 mr-1">Semua</label>
                                                                    <input type="radio" value="0" name="khusus" v-model="model.penerima" style="align-self: center">
                                                                    <label for="khusus" class="ml-1 mr-1">Khusus</label>
                                                                </div>
                                                            </div>                                               
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Student" rules="required" v-if="model.penerima == 0">
                                                        <div class="form-group">
                                                            <label class="form-label">Student</label>
                                                            <multiselect 
                                                                v-model="selectedStudent" 
                                                                id="id" 
                                                                track-by="id" 
                                                                placeholder="Pilih student" 
                                                                :options="suggestStudent" 
                                                                :multiple="true" 
                                                                @search-change="get_student_filter"
                                                                label="email"
                                                                :close-on-select="false"
                                                                :loading="isLoading"/>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    Foto Notifikasi
                                                    <div style="text-align: -webkit-center;">
                                                        <div v-if="temp_photo != null">
                                                            <img :src="temp_photo" class="img-fluid" alt="">
                                                            <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null, model.photo = null">Hapus Foto</button>
                                                        </div>
                                                        <div v-else 
                                                            style="
                                                                text-align: center; 
                                                                background: #E5E7E9;
                                                                border-radius: 8px;
                                                                height: 176px;
                                                            ">
                                                            <label for="file-upload" class="btn-edit" style="font-size: 40px; padding-top: 50px;">
                                                                <i class="fa fa-camera" aria-hidden="true"></i> 
                                                                <p style="font-size: 14px">Pilih Foto</p>
                                                            </label>
                                                            <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary float-right" :disabled="invalid">
                                    Simpan
                                </button>
                            </form>  
                        </ValidationObserver>
                        <button class="btn btn-danger float-right mr-1" v-if="$route.params.id" @click="destroy()" v-can="'notification/update'">
                            Hapus
                        </button>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                type: '',
                penerima: '1',
                users: []
            },
            temp_photo: null,
            selectedStudent: [],
            suggestStudent: [],
            isLoading: true
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/push_notification?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if (_.model.photo != null){
                        _.temp_photo = _.model.photo.url 
                    } 
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_student() {
            let _ = this
            _.isLoading = true
            _.axios.get('/student?limit=10')
                .then(resp=>{
                    _.suggestStudent = resp.data.data.rows.data
                    _.isLoading = false

                    if (_.$route.params.id){
                        let userData = JSON.parse(_.model.users)
                        for (let i = 0; i < userData.length; i++) {
                            _.selectedStudent = _.suggestStudent.find(elm => elm.id == userData[i].id_user)
                        }
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                    _.isLoading = false
                })
        },
        get_student_filter(query) {
            let _ = this
            _.isLoading = true
            _.axios.get('/student?limit=10&key='+query)
                .then(resp=>{
                    _.suggestStudent = resp.data.data.rows.data
                    _.isLoading = false

                    if (_.$route.params.id){
                        let userData = JSON.parse(_.model.users)
                        for (let i = 0; i < userData.length; i++) {
                            _.selectedStudent = _.suggestStudent.find(elm => elm.id == userData[i].id_user)
                        }
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                    _.isLoading = false
                })
        },
        save() {
            let _ = this

            _.FData =  new FormData()
            for (let i in _.model){
                if (i == 'users'){
                    _.FData.append(i, JSON.stringify(_.model[i]))    
                } else {
                    _.FData.append(i, _.model[i])
                }
            }  

            let api = _.$route.params.id ? '/push_notification/'+_.$route.params.id : '/push_notification';
            _.axios.post(api, _.FData)
                .then(resp => {                
                    _.$router.push('/notification')    
                    _.$toast.success(resp.data.message)
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })     
        },
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.photo    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        },
        destroy() {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Notifikasi ini?',                
                html:   '<p class="mb-0">Notifikasi tidak dapat dikembalikan <br> apabila sudah dihapus</p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Ya, Hapus Notifikasi',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/push_notification', { data: { id: _.$route.params.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/notification')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
    },
    watch: {
        
        selectedStudent: function(){
            this.model.users = []
            for (let i = 0; i < this.selectedStudent.length; i++) {
                this.model.users.push({
                    id_user: this.selectedStudent[i].id
                })   
            }
        }
    }
}
</script>