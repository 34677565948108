<template>
	<div class="row">    
        <ValidationObserver v-slot="{ invalid }" class="col-12 col-md-8 col-lg-6 col-xl-4 ml-auto mr-auto">
            <div class="form-auth-block">
                <div class="form-logo d-block d-lg-none">
                    <!-- <img src="/assets/logo.png" class="img-fluid" alt="">
                    <div class="sub-logo">Monitoring <span>Digital</span></div> -->
                </div>
                <img class="img-fluid mb-2" src="/assets/logo.png" alt="">
                <form @submit.prevent="login">
                    <h4 class="title-heading-form">Login</h4><br>
                    Email
                    <ValidationProvider name="email" rules="required|email">                                                
                        <div class="form-group" :class="{ 'validation-warning': errors[0] || authFalse }" slot-scope="{ errors }">                            
                            <input type="email" v-model="email" class="form-control form-custom-bg" id="user-email" placeholder="Email" required="">                            
                        </div>
                    </ValidationProvider>
                    Password
                    <ValidationProvider name="password" rules="required">
                        <div class="form-group input-group" :class="{ 'validation-warning': errors[0] || authFalse }" slot-scope="{ errors }">                            
                            <input :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control py-2 border-right-0 form-custom-bg" id="user-password" placeholder="Password" required="">
                            <span class="input-group-append">
                                <div class="input-group-text form-custom-bg">
                                    <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue">
                                        <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span>
                        </div>
                    </ValidationProvider>

                    <button type="submit" :disabled="invalid" class="btn btn-primary waves-effect waves-light w-100 mt-2">Masuk</button>
                    <p class="val-error text-center mt-1" v-if="email.length > 0 && password.length > 0 && authFalse" id="">Password atau Email Salah. Silahkan coba lagi</p>
                </form>                
            </div>            
            <img src="/assets/img/ellipse.png" class="img-fluid" alt="" style="position: absolute; bottom: 0">
        </ValidationObserver>
        <div class="col-12 col-lg-6 col-xl-8 d-none d-lg-block">
            <div class="full-img">
                <img src="/assets/img/gistet-tambun.jpg" class="img-fluid" alt="">
            </div>
        </div>        
    </div>    
</template>

<style scoped>
    .val-warning {
        font-family: "Myriad Pro";
        font-size: 14px;
        font-weight: 300;
        color: #ed1c24 !important;
    }
</style>

<script>
export default {
	name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            authFalse: false,
            showPassword: false
        }
    },
	methods: {
		login() {
            let _ = this
			_.axios.post('/auth/login', { 
                email: _.email, 
                password: _.password
            }).then(resp=>{				
                _.$store.dispatch('login', resp.data)
                if ( resp.data.role_name != 'Student'){
                    if ( resp.data.access_token ) {
                        // _.axios.post('auth/set_player_id', { 
                        //     id_user: resp.data.user.id, 
                        //     player_id: localStorage.getItem('player_id')
                        // }).then(resp=>{
                        //     console.log(resp.data.message)
                            window.location = '/'
                        // })
                    } else {
                        _.authFalse = true
                    }
                } else {
                    _.authFalse = true
                }                
            })
            .catch(err=>{
                console.log(err.message)
                _.authFalse = true
            })
		}
	}
}
</script>