<template>
  <section>
    <List
      :title="table.title"
      :breadcumb="table.breadcumb"
      :dataTable="table.dataTable"
      :icon="table.icon"
    >
      <template v-slot:search>
        <div class="row">
          <div class="col-5 input-group form-search-rounded">
            <input
              class="form-control py-2 border-right-0 border"
              v-model.lazy="search"
              type="search"
              id="example-search-input"
              placeholder="Cari"
            />
            <span class="input-group-append">
              <div class="input-group-text bg-transparent">
                <i class="fa fa-search pointer"></i>
              </div>
            </span>
          </div>
        </div>
      </template>

      <template v-slot:button>
        <div>
          <router-link :to="'/tryout/' + $route.params.id + '/add'">
            <button type="button" class="btn btn-primary">Tambah Subkategori</button>
          </router-link>
        </div>
      </template>

      <template v-slot:limit>
        <div class="row float-right">
          <div class="align-self-center text-right mr-1">Menampilkan</div>
          <div class="mr-1">
            <select v-model="limit" class="form-control">
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="0">Semua</option>
            </select>
          </div>
        </div>
      </template>

      <template v-slot:columnHeader>
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Waktu</th>
            <th>Jumlah Soal</th>
            <th>Min. Score</th>
            <th>Tgl. Buat</th>
            <th width="10%">Aksi</th>
          </tr>
        </thead>
      </template>

      <template v-slot:action="{ data }">
        <div class="btn-action-block">
          <router-link :to="$route.params.id + '/sub/' + data.id">
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light btn-rounded"
            >
              <i class="fa fa-eye"></i>
            </button>
          </router-link>
          <router-link :to="$route.params.id + '/edit/' + data.id">
            <button
              type="button"
              class="btn btn-warning waves-effect waves-light btn-rounded"
            >
              <i class="fa fa-pencil"></i>
            </button>
          </router-link>
          <button
            type="button"
            @click="destroy(data)"
            class="btn btn-danger waves-effect waves-light btn-rounded"
          >
            <i class="fa fa-trash"></i>
          </button>
          <router-link :to="$route.params.id + '/sub/' + data.id + '/question'">
            <button
              type="button"
              class="btn btn-warning waves-effect waves-light btn-rounded"
            >
              <i class="fa fa-file"></i>
            </button>
          </router-link>
          <!-- <button
            @click="download(data)"
            type="button"
            class="btn btn-primary waves-effect waves-light btn-rounded"
          >
            <i class="fa fa-print"></i>
          </button> -->
          <a
            :href="studentsdownload.url"
            @click="getListDownload(data)"
            download
            target="_blank"
            type="button"
            class="btn btn-primary waves-effect waves-light btn-rounded"
          >
            <i class="fa fa-print"></i>
          </a>
        </div>
      </template>
    </List>
  </section>
</template>

<script>
import List from "@/components/List.vue";

export default {
  name: "TryoutIndex",
  components: {
    List,
  },
  data() {
    return {
      table: {
        title: "List Data Subkategori Soal Try Out",
        icon: "icon-file-text",
        breadcumb: "List Try Out / Management Sub Try Out",
        dataTable: {
          lists: [],
          columns: ["col_tipe", "waktu", "col_jml_soal", "minimal_skor", "created_at"],
        },
      },
      studentsdownload: [],
      search: "",
      limit: 10,
      id_sub: "",
    };
  },
  mounted() {
    let _ = this;
    _.getList();
    _.getsub();
    // console.log("ID SUB", _.getsub());
    _.getListDownload();
    _.$root.$on("paginate", (data) => {
      _.getList(data);
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate");
  },
  methods: {
    // download(data) {
    //   let _ = this;

    //   _.axios
    //     .get("tryout/download-pdf?id=" + _.$route.params.id + "&id_sub=" + data.id, {
    //       // responseType: 'arraybuffer',
    //       headers: {
    //         pembahasan: 1,
    //         answer: 1,
    //       },
    //     })
    //     .then((resp) => {
    //       let blob = new Blob([resp.data], { type: "application/pdf" });
    //       let link = document.createElement("a");
    //       link.href = window.URL.createObjectURL(blob);
    //       link.download = data.col_tipe + ".pdf";
    //       link.click();
    //     })
    //     .catch((err) => {
    //       console.log(err.response);
    //     });
    // },
    getsub() {
      let _ = this;
      _.getList.data.id;
    },
    getListDownload(data) {
      let _ = this;
      _.axios
        .get("tryout/download-pdf?id=" + _.$route.params.id + "&id_sub=" + data.id)
        .then((resp) => {
          _.studentsdownload = resp.data;
          console.log("LIST DOWNLOAD", _.getListDownload);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getList(page) {
      let _ = this;
      _.axios
        .get(
          "/tryout/sub_categories?key=" +
            _.search +
            "&id_tryout=" +
            _.$route.params.id +
            "&page=" +
            page +
            "&limit=" +
            _.limit
        )
        .then((resp) => {
          _.table.dataTable.lists = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    destroy(x) {
      let _ = this;
      _.$swal
        .fire({
          title: "Hapus Subkategori Try Out?",
          html:
            '<p class="popup-title">' +
            x.col_tipe +
            "</p>" +
            '<p class="popup-text-normal">Subkategori yang sudah dihapus tidak dapat dikembalikan </p>',
          showCancelButton: true,
          cancelButtonText: "Batalkan",
          confirmButtonColor: "#EF8481",
          confirmButtonText: "Hapus Subkategori",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/tryout/sub_categories", { data: { id: x.id } })
              .then((resp) => {
                if (resp.status) {
                  _.getList();
                  _.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },
  },
  watch: {
    search: function () {
      this.getList();
    },
    limit: function () {
      this.getList();
    },
  },
};
</script>
