<template>
	<section>
		<List
            :title="table.title"
            
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">
            

            <template v-slot:customHeader>
                <div class="content-header">
                    <div class="content-header-left mb-2">
                        <div class="breadcrumbs-top">                    
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">                                
                                        <a style="display: inline-flex;">
                                            <div v-html="table.svgIcon" v-if="table.svgIcon != null"></div>
                                            {{ table.breadcumb }}
                                        </a>
                                    </li>
                                    
                                </ol>
                            </div>
                        </div>
                        <div class="breadcrumbs-top">                    
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <b>
                                            <a type="button" data-dismiss="modal" aria-label="Close">
                                                <i class="feather icon-arrow-left"></i> 
                                                Kembali
                                            </a>
                                            
                                        </b>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <h2 class="content-header-title mb-0">{{ table.title }} </h2>
                    </div>
                </div>
            </template>
            
            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Nama</th>
                        <th>Tanggal</th>
                        <th>Platform</th>
                        <th>Jenis</th>
                        <th>Status</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button type="button" v-if="id.indexOf(data.id) == -1" @click="choose(data.id)" class="btn btn-primary waves-effect waves-light">
                        Pilih
                    </button>
                    <button type="button" v-else @click="unselect(data.id)" class="btn btn-danger waves-effect waves-light">
                        Batal
                    </button>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'LiveClassIndex',
    props: ['id'],
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Live Class",
                svgIcon: `<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.334 3.83325H17.2507C17.759 3.83325 18.2465 4.03519 18.6059 4.39463C18.9654 4.75408 19.1673 5.24159 19.1673 5.74992V19.1666C19.1673 19.6749 18.9654 20.1624 18.6059 20.5219C18.2465 20.8813 17.759 21.0833 17.2507 21.0833H5.75065C5.24232 21.0833 4.75481 20.8813 4.39536 20.5219C4.03592 20.1624 3.83398 19.6749 3.83398 19.1666V5.74992C3.83398 5.24159 4.03592 4.75408 4.39536 4.39463C4.75481 4.03519 5.24232 3.83325 5.75065 3.83325H7.66732" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 9.5835H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 13.4167H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 17.25H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 9.5835H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 13.4167H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 17.25H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.3743 1.91675H8.62435C8.09508 1.91675 7.66602 2.34581 7.66602 2.87508V4.79175C7.66602 5.32102 8.09508 5.75008 8.62435 5.75008H14.3743C14.9036 5.75008 15.3327 5.32102 15.3327 4.79175V2.87508C15.3327 2.34581 14.9036 1.91675 14.3743 1.91675Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
				breadcumb: "List Live Class",
                dataTable: {
                    lists: [],
                     columns: ['name', 'col_waktu', 'platform', 'col_jenis', 'col_status']
                }
			},
            limit: 10
		}
	},
    mounted() {
        let _ = this        
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.getList()
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/live?key=' + _.search + '&jenis=' + _.filter + '&page=' + page + '&limit=' + _.limit)
                         .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        choose(data){
            let _ = this
            _.$root.$emit('pickLive', data);
            _.$root.$emit('pick', data);
        },
        unselect(data){
            let _ = this
            _.$root.$emit('unpickLive', data);
            _.$root.$emit('unpick', data);
        }
    },
    watch: {
        limit: function() {
            this.getList()
        }
    }
}
</script>