<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-file-text"></i> 
                                    List Quiz / List Student
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>                
                <h2 class="content-header-title mb-0">List Student</h2>
            </div>                                    
        </div>
		<div class="card">
            <div class="card-content">
                <div class="card-body card-dashboard">
                    <div class="row align-items-center justify-content-between">
                        <div class="col mb-1">
                            <div class="row">
                                <div class="col-5 input-group form-search-rounded">
                                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan Email">
                                    <span class="input-group-append">
                                        <div class="input-group-text bg-transparent">
                                            <i class="fa fa-search pointer"></i>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 mb-1">
                            <!-- <div class="btn-responsive float-right">
                                <button type="button" class="btn btn-primary" @click="download()" :disabled="students.length == 0 || students.total == 0">
                                    <i class="fa fa-download"></i> Download
                                </button>
                            </div> -->
                        </div>                                     
                    </div>

                    <div class="col">
                        <div class="row float-right">
                            <div class="align-self-center text-right mr-1">
                                Menampilkan
                            </div>
                            <div class="">
                                <select v-model="limit" class="form-control">                        
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    <option value="0">Semua</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th><b>No.</b></th>
                                    <th><b>Name</b></th>
                                    <th><b>Email</b></th>
                                    <th><b>Nilai</b></th>
                                    <th><b>Aksi</b></th>
                                </tr>
                            </thead>
                            <tbody v-if="students.total == 0">
                                <tr>
                                    <td :colspan="header.length + 7">Tidak ada data</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(data, index) in students" :key="index">
                                    <td class="text-center">{{ (page.current_page - 1) * limit + index + 1 }}</td>
                                    <td>{{data.name}}</td>
                                    <td>{{data.email}}</td>  
                                    <td><b>{{data.total_poin}}</b></td>
                                    <td>
                                        <button type="button" @click="destroy(data)" class="btn btn-danger waves-effect waves-light btn-rounded">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-5 mt-2">
                            Menampilkan <b>{{page.from}} - {{page.to}}</b> dari <b>{{page.total}} Data</b>
                        </div>
                        <div class="col-sm-12 col-md-7 mt-1">
                            <div class="dataTables_paginate paging_simple_numbers pull-right">
                                <ul class="pagination">
                                    <li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
                                        <button type="button" class="page-link" @click="changePage(page)">{{ page }}</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</aside>
</template>

<script>
import moment from 'moment'

export default {
    name: 'TryoutStudentIndex',
	data() {
		return {
            students: [],
            header: [],
            search: '',
            limit: 0,
            backup: [],
            page: {
                from: 0,
                to: 0,
                total: 0,
                current_page: 0,
                last_page: 0
            }
		}
	},
    mounted() {
        let _ = this
        _.getList()
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        isCurrentPage(page){
			return this.page.current_page === page
		},
		changePage(page) {
            let _ = this
			if (page > _.page.last_page) {
				page = _.page.last_page;
			}
            _.page.current_page = page;            
            _.students = _.backup
            let limited_data = _.students.slice((_.page.current_page - 1) * _.limit, _.limit * _.page.current_page)
            _.page.from = (_.page.current_page - 1) * _.limit + 1
            _.page.to = (_.limit * _.page.current_page > _.page.total) ? _.page.total : _.limit * _.page.current_page
            _.students = limited_data
			window.scrollTo(0,0);
		},
        getList() {
            let _ = this
            _.axios.get('/quiz/leaderboard?id_quiz=' + _.$route.params.id)
                .then(resp=>{
                    _.students  = resp.data.data.rows                    
                    _.backup    = _.students
                    _.limit     = 10
                    _.paginate()
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        download() {
            let _ = this
			_.axios.defaults.responseType = 'blob'
            _.axios.get('student/quiz/export?id_quiz=' + _.$route.params.id)
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data], {type:'application/vnd.ms-excel'}));
                    const link = document.createElement('a');
                    link.href = url;	
                    
                    let date = moment().format('DMYhmmss');

                    link.setAttribute('download', 'StudentQuiz-' + date + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
        },
        paginate() {
            let _ = this

            _.students = _.backup            
            let searched_data = _.students.filter(function(data) {
                return data.email.indexOf(_.search) > -1
            })

            _.students          = searched_data        
            _.page.total        = _.students.length
            
            let limited_data  = searched_data.slice(0, _.limit == 0 ? _.page.total : _.limit)

            
            _.students          = limited_data
            _.page.from         = _.page.total > 0 ? 1 : 0
            _.page.to           = _.limit == 0 ? _.page.total : _.page.total > 10 ? 10 : _.page.total
            _.page.current_page = 1
            _.page.last_page    = Math.ceil(_.page.total / (_.limit == 0 ? _.page.total : _.limit))
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Student?',                
                html:   '<p class="popup-title">'+ x.name + '</p>' +
                        '<p class="popup-text">'+ x.email + '</p>' +
                        '<p class="popup-text-normal">Student yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Student',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/student/quiz', { 
                                data: { 
                                    id_quiz: _.$route.params.id, 
                                    email: x.email
                                }
                            })
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.paginate()
        },
        limit: function(){
            this.paginate()
        }
    },
    computed: {
		pages() {
			let current         = this.page.current_page
			let last            = this.page.last_page
			let delta           = 2
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
	}
}
</script>