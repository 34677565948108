<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-file-text"></i> 
                                    List Voucher / {{$route.params.id ? 'Edit' : 'Tambah'}} Voucher
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/voucher">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Voucher</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Nama voucher" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Nama Voucher</label>
                                                            <input type="text" v-model="model.name" class="form-control"
                                                                placeholder="Masukkan nama voucher">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Deskripsi</label>
                                                            <textarea cols="30" rows="5" class="form-control" v-model="model.deskripsi" placeholder="Tulis deskripsi"></textarea>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <div class="row">
                                                        <div class="form-group mr-2 col">
                                                            <label for="">Tanggal Awal</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Tanggal awal" rules="required" v-slot="{ errors }">
                                                                    <date-picker
                                                                        v-model="model.start_date"
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        placeholder="Masukan tanggal awal"
                                                                        style="width: 100%;"
                                                                        >
                                                                    </date-picker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col">
                                                            <label for="">Tanggal Selesai</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Tanggal selesai" rules="required" v-slot="{ errors }">
                                                                    <date-picker
                                                                        v-model="model.end_date"
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        placeholder="Masukan tanggal selesai"
                                                                        style="width: 100%;"
                                                                        >
                                                                    </date-picker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Status</label>
                                                            <select v-model="model.status" class="form-control">
                                                                <option value="0">Tidak Aktif</option>
                                                                <option value="1">Aktif</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    <div class="row">
                                                        <div class="col">
                                                            <ValidationProvider name="Tipe voucher" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Tipe Voucher</label>
                                                                    <select v-model="model.tipe" class="form-control" @change="selectedIdKategori = []">
                                                                        <option value="0">Tryout</option>
                                                                        <option value="1">Materi</option>
                                                                        <option value="2">Quiz</option>
                                                                        <option value="3">Package</option>
                                                                    </select>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col">
                                                            <ValidationProvider name="ID kategori" v-slot="{ errors }">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">ID Kategori</label>
                                                                    <input type="text" v-model="selectedIdKategori" class="form-control"
                                                                        placeholder="Pilih ID kategori" readonly>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-2 align-self-center">
                                                            <a data-target="#modalTable" data-toggle="modal"><u>Cari ID Kategori</u></a>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <ValidationProvider name="Kode Voucher" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Kode Voucher</label>
                                                                    <input type="text" id="name" class="form-control" placeholder="Masukkan kode" v-model="model.kode_voucher">
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col">
                                                            <ValidationProvider name="Jumlah voucher" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Jumlah Voucher</label>
                                                                    <input type="number" class="form-control" placeholder="0" v-model="model.jumlah">
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary float-right" :disabled="invalid">
                                    Simpan Voucher
                                </button>
                                <router-link to="/voucher" v-if="!$route.params.id">
                                    <button type="button" class="btn btn-outline-success mr-1 float-right">
                                        Cancel
                                    </button>
                                </router-link>
                                <button type="button" @click="destroy($route.params.id)" class="btn btn-danger mr-1 float-right" v-else>
                                    Hapus
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
        <div class="modal fade" id="modalTable" tabindex="-1" role="dialog" aria-labelledby="setting" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" style="min-width: 90%;" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <LookupTryout v-if="model.tipe == 0" :id="selectedIdKategori"></LookupTryout>
                        <LookupMateri v-if="model.tipe == 1" :id="selectedIdKategori"></LookupMateri>
                        <LookupQuiz v-if="model.tipe == 2" :id="selectedIdKategori"></LookupQuiz>
                        <LookupPackage v-if="model.tipe == 3" :id="selectedIdKategori"></LookupPackage>
                    </div>
                </div>
            </div>
        </div>
	</aside>
</template>

<script>
import LookupMateri from '@/views/materi/Lookup.vue'
import LookupTryout from '@/views/tryout/Lookup.vue'
import LookupQuiz from '@/views/quiz/Lookup.vue'
import LookupPackage from '@/views/package/Lookup.vue'

export default {
	name: 'VoucherForm',
    components: {
        LookupMateri,
        LookupTryout,
        LookupQuiz,
        LookupPackage
    },
    data() {
        return {            
            model: {
                tipe: 0,
                status: 1,
                id_kategori: null,
                kode_voucher: null,
                tipe_voucher: 1,
                jumlah_voucher: null
            },
            selectedIdKategori: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/voucher?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    _.selectedIdKategori.push(_.model.id_kategori)
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            _.model.id_kategori = _.selectedIdKategori[0]

            if (_.$route.params.id){
                _.axios.patch('/voucher', _.model)
                    .then(resp => {                
                        _.$router.push('/voucher')    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.post('/voucher', _.model)
                    .then(resp => {                
                        _.$router.push('/voucher')    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            }            
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Voucher?',                
                html: '<p class="popup-text-normal">Voucher tidak dapat dikembalikan apabila sudah dihapus </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Voucher',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/voucher', { data: { id: x }})
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/voucher')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        makeid(length) {
            var result           = [];
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
            }
            return result.join('');
        },
        generateVoucher(){
            let _ = this
            let data = []
            for (let i = 0; i < _.model.jumlah; i++) {
                data[i] = _.makeid(6)
            }
            _.model.voucher_codes = data
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id){
            _.get()
        }

        _.$root.$on("pick", (data) => {
            _.selectedIdKategori = []
            _.selectedIdKategori.push(data)
        });

        _.$root.$on("unpick", (data) => {
            if (data) {
                _.selectedIdKategori = []
            }
        });
    },
    watch: {
        'model.jumlah': function(){
            this.model.kode_vouchers = null
        }
    }
}
</script>