<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan ID Try Out">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                    <div class="col-4 input-group form-search-rounded">
                        <select name="" class="form-control" id="" v-model="filter.jenjang">
                            <option value="">Pilih jenjang</option>
                            <option v-for="(data, index) in option_jenjang" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="col-3 input-group form-search-rounded">
                        <select name="" class="form-control" id="" v-model="filter.materi">
                            <option value="">Pilih materi</option>
                            <option v-for="(data, index) in option_materi.filter(data => data.id_level == filter.jenjang)" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                </div>                
			</template>

			<template v-slot:button>
				<div>
                    <router-link to="/tryout/add" v-can="'tryout/create'">
                        <button type="button" class="btn btn-primary">
                            Tambah Try Out
                        </button>
                    </router-link>
				</div>
			</template> 

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No</th>
                        <th>ID</th>
                        <th>Judul</th>
                        <th>Type</th>
                        <th>Deskripsi</th>
                        <th width="10%">Harga</th>
                        <th>Tgl. Buat</th>
                        <th>Status</th>
                        <th>Gabung Waktu</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">                    
                    <button type="button" @click="reorder(0, data.id)" class="btn btn-primary waves-effect waves-light btn-rounded" v-can="'tryout/reorder'">
                        <i class="fa fa-arrow-up"></i>
                    </button>
                    <button type="button" @click="reorder(1, data.id)" class="btn btn-danger waves-effect waves-light btn-rounded" v-can="'tryout/reorder'">
                        <i class="fa fa-arrow-down"></i>
                    </button>
                    |
                    <router-link :to="'tryout/' + data.id" v-can="'tryout/detail'">
                        <button type="button" class="btn btn-primary waves-effect waves-light btn-rounded">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                    <router-link :to="'tryout/edit/' + data.id" v-can="'tryout/update'">
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded">
                            <i class="fa fa-pencil"></i>
                        </button>
                    </router-link>
                    <button type="button" @click="copy(data)" class="btn btn-secondary waves-effect waves-light btn-rounded" v-can="'tryout/duplicate'">
                        <i class="fa fa-copy"></i>
                    </button>
                    <router-link :to="'tryout/' + data.id + '/students'" v-can="'tryout/view student'">
                        <button type="button" class="btn btn-primary waves-effect waves-light btn-rounded">
                            <i class="fa fa-user"></i>
                        </button>
                    </router-link>
                    <button type="button" @click="destroy(data)" class="btn btn-danger waves-effect waves-light btn-rounded" v-can="'tryout/delete'">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'TryoutIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Try Out",
                svgIcon: `<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.375 1H2.875C2.37772 1 1.90081 1.19754 1.54917 1.54917C1.19754 1.90081 1 2.37772 1 2.875V17.875C1 18.3723 1.19754 18.8492 1.54917 19.2008C1.90081 19.5525 2.37772 19.75 2.875 19.75H14.125C14.6223 19.75 15.0992 19.5525 15.4508 19.2008C15.8025 18.8492 16 18.3723 16 17.875V6.625L10.375 1Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.375 1V6.625H16" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.25 11.3125H4.75" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.25 15.0625H4.75" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.625 7.5625H5.6875H4.75" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
				breadcumb: "List Try Out",
                dataTable: {
                    lists: [],
                    columns: ['id', 'name', 'col_level', 'deskripsi', 'harga_rupiah', 'created_at', 'col_status_publik', 'col_status_gabung_waktu']
                }
			},
            search: '',
            filter: {
                jenjang: '',
                materi: ''
            },
            option_jenjang: [],
            option_materi: [],
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.get_jenjang()
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/tryout?key=' + encodeURIComponent(_.search) + '&page=' + page + '&id_level=' + _.filter.jenjang + '&id_theory=' + _.filter.materi + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Try Out?',                
                html:   '<p class="popup-title">'+ x.name + '</p>' +
                        '<p class="popup-text">'+ x.col_level + ' - ' + x.col_theory + '</p>' +
                        '<p class="popup-text-muted">'+ x.col_jml_sub_tryout + ' Jenis Tes - '+ x.col_jml_question + ' Soal</p>' +
                        '<p class="popup-text-muted">Waktu - '+ x.col_total_waktu/60 + ' Menit</p>' +
                        '<p class="popup-text-normal">Try Out yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Try Out',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/tryout', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.fix_order()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        get_jenjang() {
            let _ = this
            _.axios.get('/level/all')
                .then(resp=>{
                    _.option_jenjang = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_materi() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp=>{
                    _.option_materi = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        reorder($dir, $id_tryout){
            let _ = this
            let $data = {
                id_tryout: $id_tryout,
                direction: $dir
            };

            _.axios.post('/tryout/reorder', $data)
                .then(resp=>{
                    if (resp.data.status){
                        _.$toast.success(resp.data.message)
                        _.getList()
                    } else {
                        _.$toast.error(resp.data.message)
                    }                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        copy(x) {
            let _ = this                       
            _.$swal.fire({
                title: 'Duplikat Tryout',
                input: 'text',
                inputValue: x.name,
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#79d192',
                confirmButtonText: 'Simpan',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Nama tidak boleh kosong!'
                    } else if (value == x.name) {
                        return 'Nama tidak boleh sama!'
                    }
                }
            }).then((result) => {
                if (result.value) {
                    let data = {
                        id_tryout: x.id,
                        name: result.value
                    }

                    _.axios.post('/tryout/duplicate', data)
                        .then(resp => {
                            if (resp.status) {
                                _.getList()
                                _.$swal.fire({
                                    title: 'Berhasil Duplikasi!',
                                    text: "Data berhasil diduplikat",
                                    icon: 'success',
                                    confirmButtonColor: '#37BA71',
                                    confirmButtonText: 'OK'
                                })
                            }else{
                                console.log("Failed")
                            }
                        })
                        .catch(err=>{
                            _.$swal.fire({
                                title: 'Gagal Duplikasi!',
                                text: err.response.data.message,
                                icon: 'error',
                                confirmButtonColor: '#37BA71',
                                confirmButtonText: 'OK'
                            })
                        })  
                }
            })          
        },
        fix_order(){
            let _ = this
            _.axios.post('/tryout/fix_order')
                .then(resp => {
                    _.$toast.success(resp.data.message)
                })
                .catch(err=>{
                    _.$toast.danger(err.response.data.message)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        'filter.jenjang': function(){
            let _ = this
            _.get_materi()
            _.getList()
            _.filter.materi = ''
        },    
        'filter.materi': function(){
            this.getList()
        },
        limit: function() {
            this.getList()
        }
    }
}
</script>