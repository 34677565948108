<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-users"></i> 
                                    List Materi / {{$route.params.id ? 'Edit' : 'Tambah'}} Materi
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/master">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Materi</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <ValidationProvider name="Jenjang" v-slot="{ errors }" rules="required">
                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Jenjang</label>
                                                    <select v-model="model.id_level" class="form-control">
                                                        <option value="">Pilih jenjang</option>
                                                        <option v-for="(data, index) in option_jenjang" :key="index" :value="data.id">{{data.name}}</option>
                                                    </select>
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                            <ValidationProvider name="Nama" v-slot="{ errors }" rules="required">
                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Nama</label>
                                                    <input type="text" v-model="model.name" id="name" class="form-control"
                                                        placeholder="Masukkan nama">
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary float-right" :disabled="invalid">
                                    {{$route.params.id ? 'Edit' : 'Tambah'}} Materi
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'MateriForm',
    data() {
        return {            
            model: {
                id_level: ''                
            },
            option_jenjang: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/theory?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_jenjang() {
            let _ = this
            _.axios.get('/level/all')
                .then(resp=>{
                    _.option_jenjang = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this

            if (_.$route.params.id){
                _.axios.patch('/theory', _.model)
                    .then(resp => {                    
                        _.$toast.success(resp.data.message)
                        _.$router.push('/master')
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.post('/theory', _.model)
                .then(resp => {                    
                    _.$toast.success(resp.data.message)
                    _.$router.push('/master')
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
            }
        }
    },
    mounted() {
        let _ = this
        _.get_jenjang()
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>