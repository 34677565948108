<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :icon="table.icon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

			<template v-slot:button>
				<div>                    
                    <button type="button" @click="save()" class="btn btn-primary">
                        Tambah Soal
                    </button>
				</div>
			</template>	

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Pertanyaan</th>
                        <th>Jawaban</th>
                        <th>Pembahasan</th>
                        <th>Bobot</th>
                        <th>History Try Out</th>
                        <th width="10%"><input type="checkbox"></th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <input type="checkbox" @click="question(data.id, $event)" :checked="model.question.some((obj)=> obj == data.id)">
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'BankSoalDetail',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Data Soal Pelajaran",
                icon: "icon-book-open",
				breadcumb: "Materi / Sub Materi / Konten / Latihan / Pelajaran",
                dataTable: {
                    lists: [],
                    columns: ['soal', 'col_jawaban', 'pembahasan', 'col_bobot', 'col_history']
                }
            },
            search: '',
            model: {
                id_subject_content: this.$route.params.id_konten,
                question: []
            },
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getSelected()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/course/question?key=' + _.search + '&id_course=' + _.$route.params.id_course + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getSelected() {
            let _ = this
            _.axios.get('/sub_category/chapter/content/question/selected?id_subject_content=' + _.$route.params.id_konten)
                .then(resp=>{
                    _.model.question = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        question(value, event){
            let _ = this
            if (event.target.checked) {
                _.model.question.push(value)                
            } else {
                const index = _.model.question.indexOf(value);
                if (index > -1) {
                    _.model.question.splice(index, 1);
                }
            }
        },
        save(){
            let _ = this
            _.axios.post('/sub_category/chapter/content/question', _.model)
                .then(resp => {                   
                    _.$toast.success(resp.data.message)
                    _.$router.push('/sub/' + this.$route.params.id + '/konten/' + this.$route.params.id_konten)
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        limit: function() {
            this.getList()
        }
    }
}
</script>