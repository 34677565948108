<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan Materi">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                    <div class="col-4 input-group form-search-rounded">
                        <select name="" class="form-control" id="" v-model="filter.jenjang">
                            <option value="">Pilih jenjang</option>
                            <option v-for="(data, index) in option_jenjang" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="col-3 input-group form-search-rounded">
                        <select name="" class="form-control" id="" v-model="filter.kategori">
                            <option value="">Pilih kategori</option>
                            <option v-for="(data, index) in option_kategori.filter(data => data.id_level == filter.jenjang)" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                </div>                
			</template>

			<template v-slot:button>
				<div>
                    <router-link to="/materi/add" v-can="'theory/create'">
                        <button type="button" class="btn btn-primary">
                            Tambah Materi
                        </button>
                    </router-link>
				</div>
			</template> 

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Photo</th>
                        <th>Judul</th>
                        <th>Jenjang</th>
                        <th>Kategori</th>
                        <th>Tgl. Buat</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">             
                    <router-link :to="'materi/' + data.id + '/sub'" v-can="'theory/detail'">
                        <button type="button" class="btn btn-primary waves-effect waves-light btn-rounded">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                    <router-link :to="'materi/edit/' + data.id" v-can="'theory/update'">
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded">
                            <i class="fa fa-pencil"></i>
                        </button>
                    </router-link>
                    <button type="button" @click="destroy(data)" class="btn btn-danger waves-effect waves-light btn-rounded" v-can="'theory/delete'">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'MateriIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "Materi",
                svgIcon: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 7.18751C11.2188 4.35024 13.9334 2.90151 19.8438 2.87501C19.9382 2.87465 20.0319 2.893 20.1192 2.92899C20.2066 2.96499 20.286 3.01791 20.3528 3.08473C20.4196 3.15154 20.4725 3.23092 20.5085 3.31828C20.5445 3.40564 20.5629 3.49927 20.5625 3.59376V16.5313C20.5625 16.7219 20.4868 16.9047 20.352 17.0395C20.2172 17.1743 20.0344 17.25 19.8438 17.25C14.0938 17.25 11.8724 18.4094 10.5 20.125C9.13573 18.418 6.90626 17.25 1.15626 17.25C0.712427 17.25 0.437505 16.8884 0.437505 16.4446V3.59376C0.437149 3.49927 0.455497 3.40564 0.491491 3.31828C0.527485 3.23092 0.580414 3.15154 0.647228 3.08473C0.714041 3.01791 0.793417 2.96499 0.88078 2.92899C0.968144 2.893 1.06177 2.87465 1.15626 2.87501C7.06663 2.90151 9.78125 4.35024 10.5 7.18751Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.5 7.1875V20.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
				breadcumb: "Materi",
                dataTable: {
                    lists: [],
                    columns: ['photo', 'name', 'col_level_name', 'col_theory_name', 'created_at']
                }
			},
            search: '',
            filter: {
                jenjang: '',
                kategori: ''
            },
            option_jenjang: [],
            option_kategori: [],
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.get_jenjang()
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/sub_category?key=' + _.search + '&page=' + page + '&id_level=' + _.filter.jenjang + '&id_theory=' + _.filter.kategori + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Materi?',                
                html:   '<p class="popup-title">'+ x.name + '</p>' +
                        '<p class="popup-text-normal">Materi yang sudah dihapus tidak dapat dikembalikan </p>' + 
                        '<p class="popup-text-normal">& Sub-Tryout yang berhubungan dengan Materi ini juga akan terhapus.</p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Materi',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/sub_category', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        get_jenjang() {
            let _ = this
            _.axios.get('/level/all')
                .then(resp=>{
                    _.option_jenjang = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_kategori() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp=>{
                    _.option_kategori = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        'filter.jenjang': function(){
            let _ = this
            _.get_kategori()
            _.getList()
            _.filter.kategori = ''
        },    
        'filter.kategori': function(){
            this.getList()
        },
        limit: function() {
            this.getList()
        }
    }
}
</script>