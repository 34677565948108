<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">

            <template v-slot:search>
                <div class="row">
                    <div class="col-6 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari reward...">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                </div>
			</template>

			<template v-slot:button>
				<div class="row">
                    <router-link to="/reward/add" v-can="'reward/create'">
                        <button type="button" class="btn btn-primary mr-1">
                            Tambah Reward
                        </button>
                    </router-link>
				</div>
			</template>

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Judul Reward</th>
                        <th>Gambar Reward</th>
                        <th>Harga Saku Reward</th>
                        <th>Harga Diamond Reward</th>
                        <th>Berlaku Sampai</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="'/reward/' + data.id">
                        <button type="button" class="btn btn-primary waves-effect waves-light">
                            Detail
                        </button>
                    </router-link>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'UserIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Reward",
                svgIcon: `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M17.2187 16.6252H1.78126C0.799027 16.6252 0 15.8261 0 14.8439V11.8751C0 11.5469 0.265556 11.2814 0.593754 11.2814C1.57599 11.2814 2.37501 10.4824 2.37501 9.50013C2.37501 8.51789 1.57599 7.71887 0.593754 7.71887C0.265556 7.71891 0 7.45335 0 7.12515V4.15638C0 3.17415 0.799027 2.37512 1.78126 2.37512H17.2188C18.201 2.37512 19 3.17415 19 4.15638V7.12515C19 7.45335 18.7345 7.71891 18.4063 7.71891C17.4241 7.71891 16.625 8.51793 16.625 9.50017C16.625 10.4824 17.4241 11.2814 18.4063 11.2814C18.7345 11.2814 19 11.547 19 11.8752V14.844C19 15.8261 18.201 16.6252 17.2187 16.6252ZM1.18751 12.4092V14.8439C1.18751 15.1715 1.45366 15.4377 1.78126 15.4377H17.2188C17.5464 15.4377 17.8125 15.1715 17.8125 14.8439V12.4092C16.4592 12.1332 15.4375 10.9341 15.4375 9.50013C15.4375 8.06617 16.4592 6.86711 17.8125 6.59108V4.15638C17.8125 3.82879 17.5464 3.56263 17.2188 3.56263H1.78126C1.45366 3.56263 1.18751 3.82879 1.18751 4.15638V6.59112C2.54083 6.86711 3.56252 8.06621 3.56252 9.50017C3.56252 10.9341 2.54083 12.1332 1.18751 12.4092Z" fill="white"/>
                                <path d="M7.16406 14.0337L10.7253 4.53705L11.8372 4.95401L8.27594 14.4506L7.16406 14.0337Z" fill="white"/>
                                <path d="M6.69142 9.69973C5.70918 9.69973 4.91016 8.9007 4.91016 7.91847C4.91016 6.93623 5.70918 6.13721 6.69142 6.13721C7.67365 6.13721 8.47268 6.93623 8.47268 7.91847C8.47268 8.9007 7.67365 9.69973 6.69142 9.69973ZM6.69142 7.32471C6.36382 7.32471 6.09766 7.59087 6.09766 7.91847C6.09766 8.24606 6.36382 8.51222 6.69142 8.51222C7.01902 8.51222 7.28517 8.24606 7.28517 7.91847C7.28517 7.59087 7.01902 7.32471 6.69142 7.32471Z" fill="white"/>
                                <path d="M12.8281 13.3823C11.8459 13.3823 11.0469 12.5833 11.0469 11.6011C11.0469 10.6189 11.8459 9.81982 12.8281 9.81982C13.8104 9.81982 14.6094 10.6189 14.6094 11.6011C14.6094 12.5833 13.8104 13.3823 12.8281 13.3823ZM12.8281 11.0073C12.5005 11.0073 12.2344 11.2735 12.2344 11.6011C12.2344 11.9287 12.5005 12.1948 12.8281 12.1948C13.1557 12.1948 13.4219 11.9287 13.4219 11.6011C13.4219 11.2735 13.1557 11.0073 12.8281 11.0073Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="19" height="19" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>`,
				breadcumb: "List Reward",
                dataTable: {
                    lists: [],
                    columns: ['title', 'image', 'price_wallet', 'price_diamond', 'expired_at']
                }
			},
            search: '',
            filter: '',
            limit: 10,
            orderby:'created_at',
            sort:'desc'
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get(`/reward?page=${page}&limit=${_.limit}&search=${_.search}`)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>
