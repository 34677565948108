<template>
	<div>
		<div class="content-header" v-if="breadcumb">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item" v-if="breadcumb">
                                <a style="display: inline-flex;">
                                    <i class="feather" :class="icon" v-if="icon != null"></i>
                                    <div v-html="svgIcon" v-if="svgIcon != null"></div>
                                    {{ breadcumb }}
                                </a>
                            </li>

                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <a @click="$router.back()">
                                        <i class="feather icon-arrow-left"></i>
                                        Kembali
                                    </a>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{ title }} </h2>
            </div>
        </div>
        <slot name="customHeader"></slot>
        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col mb-1">
                                            <slot name="search"></slot>
                                        </div>
                                        <div class="col-4 mb-1">
                                            <div class="btn-responsive float-right">
                                                <slot name="button"></slot>
                                                <slot name="limit2"></slot>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col mb-1">
                                            <div class="btn-responsive">
                                                <slot name="filter"></slot>
                                            </div>
                                        </div>
                                        <div class="col mb-1">
                                            <slot name="limit"></slot>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <slot name="columnHeader"></slot>
                                            <tbody>
                                                <tr v-for="(data, key) in dataTable.lists.data" :key="key">
                                                    <td class="text-center">{{ (dataTable.lists.current_page - 1) * dataTable.lists.per_page + key + 1 }}</td>
                                                    <td v-for="(column, kcolumn) in dataTable.columns" :key="kcolumn">
                                                        <span v-if="column == 'created_at' && $route.name == 'TransactionIndex'">
                                                            {{ data[column]      }} WIB
                                                        </span>
                                                        <span v-else-if="(column == 'created_at' || column == 'updated_at') && ($route.name == 'RoleIndex' || $route.name == 'AdminIndex' || $route.name == 'VoucherIndex')">
                                                            {{ data[column] | moment("DD/MM/YYYY HH.m") }} WIB
                                                        </span>
                                                        <span v-else-if="column == 'created_at' || column == 'expired_at'">
                                                            {{ data[column] | moment("DD/MM/YYYY") }}
                                                        </span>
                                                        <span v-else-if="column == 'harga_rupiah' || column == 'total'">
                                                            {{ data[column] | currency }}
                                                        </span>
                                                        <span v-else-if="column == 'waktu'">
                                                            {{ data[column] | sectomin }} Menit
                                                        </span>
                                                        <span v-else-if="column == 'col_jml_soal' || column == 'col_jml_question'">
                                                            {{ data[column] }} Soal
                                                        </span>
                                                        <span v-else-if="column == 'photo' || column == 'image' || column == 'foto'">
                                                            <img :src="data[column]" class="avatar-small" alt="">
                                                        </span>
                                                       
                                                        <span v-else-if="column == 'jenjang'">
                                                            <p v-for="(dt, idx) in data[column]" :key="idx" class="m-0">
                                                                {{ dt.name }}
                                                            </p>
                                                        </span>
                                                        <span v-else-if="column == 'status'">
                                                            {{ data[column] == 1 ? 'Aktif' : 'Tidak Aktif' }}
                                                        </span>
                                                        <span v-else-if="column == 'id'">
                                                            #{{ data[column] }}
                                                        </span>
                                                        <span v-else v-html="data[column]"></span>
                                                    </td>
                                                    <td>
                                                        <slot name="action" v-bind:data="data"></slot>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="col text-center mt-1">
                                            <p v-if="dataTable.lists.total == 0">Tidak ada data.</p>
                                        </div>
                                    </div>
                                    <PaginateList :data="dataTable.lists" v-if="dataTable.lists.total != 0"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
import PaginateList from '@/components/PaginateList'

export default {
	name: 'List',
    props: ['title', 'breadcumb', 'dataTable',  'isBreadcrumb', 'icon', 'svgIcon'],
    components: {
        PaginateList
    },
    data() {
        return {
            role_id: localStorage.getItem('role_id')
        }
    }
}
</script>
