<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-6 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan Judul">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                    <!-- <div class="col-4 input-group form-search-rounded">
                        <select name="" class="form-control" id="">
                            <option value="">Pilih berdasarkan</option>
                        </select>
                    </div> -->
                </div>                
			</template>

			<template v-slot:button>
				<div>
                    <router-link to="/notification/add" v-can="'notification/create'">
                        <button type="button" class="btn btn-primary">
                            Tambah Notif
                        </button>
                    </router-link>
				</div>
			</template> 

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No</th>
                        <th>Tanggal Kirim</th>
                        <th>Judul</th>
                        <th>Isi</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="'/notification/' + data.id" v-can="'notification/read'">
                        <button type="button" class="btn btn-primary waves-effect waves-light">
                            Detail
                        </button>
                    </router-link>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'UserIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Push Notification",
                svgIcon: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M19.0301 5.98522L20.2652 5.61638C19.575 3.3053 18.0517 1.34356 15.9759 0.0925293L15.3105 1.19661C17.111 2.28162 18.4319 3.98228 19.0301 5.98522Z" fill="white"/>
                                <path d="M6.68906 1.19657L6.02369 0.0925293C3.94782 1.34356 2.42454 3.3053 1.73438 5.61638L2.96951 5.98522C3.56768 3.98228 4.88862 2.28162 6.68906 1.19657Z" fill="white"/>
                                <path d="M3.91016 8.37891V15.2877C3.91016 15.7034 3.74829 16.0941 3.45443 16.388C2.91706 16.9253 2.62109 17.6398 2.62109 18.3998V19.4219H7.84218C8.14159 20.8911 9.44367 22 11 22C12.5563 22 13.8584 20.8911 14.1578 19.4219H19.3789V18.3998C19.3789 17.6398 19.0829 16.9253 18.5456 16.388C18.2517 16.0941 18.0898 15.7034 18.0898 15.2877V8.37891C18.0898 4.68686 15.2529 1.64545 11.6445 1.31867V0H10.3555V1.31867C6.74708 1.6454 3.91016 4.68682 3.91016 8.37891ZM11 20.7109C10.1597 20.7109 9.44333 20.1721 9.17727 19.4214H12.8227C12.5567 20.1721 11.8403 20.7109 11 20.7109ZM16.8008 8.37891V15.2877C16.8008 16.0477 17.0968 16.7622 17.6341 17.2995C17.8641 17.5295 18.0132 17.8188 18.0672 18.1328H3.93284C3.98681 17.8187 4.13596 17.5294 4.36593 17.2995C4.90325 16.7622 5.19922 16.0477 5.19922 15.2877V8.37891C5.19922 5.18036 7.80145 2.57812 11 2.57812C14.1986 2.57812 16.8008 5.18036 16.8008 8.37891Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="22" height="22" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>`,
				breadcumb: "Push Notification",
                dataTable: {
                    lists: [],
                    columns: ['created_at', 'name', 'description', 'col_type', 'type']
                }
			},
            search: '',
            filter: '',
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/push_notification?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>