<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :icon="table.icon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Kode Pelajaran</th>
                        <th>Pelajaran</th>
                        <th>Jumlah Soal</th>
                        <th>Tgl. Buat</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">                    
                    <button type="button" @click="cancel(data)" v-if="data.is_choosen" class="btn btn-danger waves-effect waves-light">
                        Batal
                    </button>
                    <button type="button" @click="choose(data)" v-else class="btn btn-primary waves-effect waves-light">
                        Pilih
                    </button>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'BankSoalIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Pelajaran",
                icon: "icon-book-open",
				breadcumb: "List Pelajaran",
                dataTable: {
                    lists: [],
                    columns: ['kode', 'name', 'col_jml_question', 'created_at']
                }
            },
            search: '',
            currentPage: 1
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.currentPage = page
            _.axios.get('/course?key=' + _.search +'&page=' + page, 
                { 
                    headers: { 
                        'id_sub_tryout_category': _.$route.params.id_sub
                    } 
                })
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        choose(x){
            let _ = this
            let data = {
                id_course: x.id,
                id_sub_tryout_category: _.$route.params.id_sub
            }
            _.axios.post('/tryout/sub_courses', data)
                .then(resp => {                   
                    _.$toast.success(resp.data.message)
                    _.getList(_.currentPage)
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
        },
        cancel(x){
            let _ = this
            _.axios.delete('/tryout/sub_courses', { data: { id_course: x.id,  id_sub_tryout_category: _.$route.params.id_sub}})
                .then(resp => {                   
                    _.$toast.success(resp.data.message)
                    _.getList(_.currentPage)
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        }
    }
}
</script>