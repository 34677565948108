<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 7.18751C11.2188 4.35024 13.9334 2.90151 19.8438 2.87501C19.9382 2.87465 20.0319 2.893 20.1192 2.92899C20.2066 2.96499 20.286 3.01791 20.3528 3.08473C20.4196 3.15154 20.4725 3.23092 20.5085 3.31828C20.5445 3.40564 20.5629 3.49927 20.5625 3.59376V16.5313C20.5625 16.7219 20.4868 16.9047 20.352 17.0395C20.2172 17.1743 20.0344 17.25 19.8438 17.25C14.0938 17.25 11.8724 18.4094 10.5 20.125C9.13573 18.418 6.90626 17.25 1.15626 17.25C0.712427 17.25 0.437505 16.8884 0.437505 16.4446V3.59376C0.437149 3.49927 0.455497 3.40564 0.491491 3.31828C0.527485 3.23092 0.580414 3.15154 0.647228 3.08473C0.714041 3.01791 0.793417 2.96499 0.88078 2.92899C0.968144 2.893 1.06177 2.87465 1.15626 2.87501C7.06663 2.90151 9.78125 4.35024 10.5 7.18751Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.5 7.1875V20.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Materi / {{$route.params.id ? 'Edit' : 'Tambah'}} Materi
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/materi">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Materi</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div style="text-align: -webkit-center;">
                                                        <div v-if="temp_photo != null">
                                                            <img :src="temp_photo" class="img-fluid" alt="">
                                                            <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null, model.photo = null">Hapus Foto</button>
                                                        </div>
                                                        <div v-else 
                                                            style="
                                                                text-align: center; 
                                                                height: 150px; 
                                                                width: 150px; 
                                                                border: 1px solid #bdbdbd; 
                                                                border-radius: 50%; 
                                                                background: #FAFAFA;
                                                            ">
                                                            <label for="file-upload" class="btn-edit" style="font-size: 40px; margin-top: 27px;">
                                                                <i class="fa fa-camera" aria-hidden="true"></i> 
                                                                <p style="font-size: 14px">Pilih Foto</p>
                                                            </label>
                                                            <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Nama" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Nama</label>
                                                            <input type="text" v-model="model.name" id="name" class="form-control"
                                                                placeholder="Masukkan nama">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="row">
                                                        <div class="col">
                                                            <ValidationProvider name="Jenjang" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Jenjang</label>
                                                                    <select v-model="model.id_level" class="form-control">
                                                                        <option value="">Pilih jenjang</option>
                                                                        <option v-for="(data, index) in option_jenjang" :key="index" :value="data.id">{{data.name}}</option>
                                                                    </select>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col">
                                                            <ValidationProvider name="Kategori" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Kategori</label>
                                                                    <select v-model="model.id_theory" class="form-control">
                                                                        <option value="">Pilih kategori</option>
                                                                        <option v-for="(data, index) in option_kategori.filter(v => v.id_level == model.id_level)" :key="index" :value="data.id">{{data.name}}</option>
                                                                    </select>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary float-right" :disabled="invalid">
                                    {{$route.params.id ? 'Edit' : 'Tambah'}} Materi
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'SubkategoriForm',
    data() {
        return {            
            model: {
                id_level: '',
                id_theory: ''
            },
            temp_photo: null,
            option_kategori: [],
            option_jenjang: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/sub_category?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if(_.model.photo != null){
                        _.temp_photo = _.model.photo
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this

            if (_.temp_photo == null){
                _.$toast.warning('Foto harus diisi')
                return;
            }

            if (_.$route.params.id){
                _.axios.patch('/sub_category', _.model)
                    .then(resp => {                    
                        _.$toast.success(resp.data.message)
                        _.$router.push('/materi')
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.post('/sub_category', _.model)
                .then(resp => {                    
                    _.$toast.success(resp.data.message)
                    _.$router.push('/materi')
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
            }
        },
        Addphoto(ev) {             
            let _ = this
            const file = ev.target.files[0];
            var reader = new FileReader();
            reader.onload = (function() {
                return function(e) {
                    var binaryData      = e.target.result;
                    var base64String    = window.btoa(binaryData);
                    _.model.photo       = 'data:image/jpeg;base64,' + base64String;
                    _.temp_photo        = _.model.photo
                };
            })(file);
            reader.readAsBinaryString(file);
        },
        get_jenjang() {
            let _ = this
            _.axios.get('/level/all')
                .then(resp=>{
                    _.option_jenjang = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_kategori() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp=>{
                    _.option_kategori = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
        _.get_jenjang()
        _.get_kategori()
    }
}
</script>