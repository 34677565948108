<template>
  <section>
    <List
      :title="table.title"
      :breadcumb="table.breadcumb"
      :dataTable="table.dataTable"
      :svgIcon="table.svgIcon"
    >
      <template v-slot:search>
        <div class="row">
          <div class="col-6 input-group form-search-rounded">
            <input
              class="form-control py-2 border-right-0 border"
              v-model.lazy="search"
              type="search"
              id="example-search-input"
              placeholder="Masukkan ID User"
            />
            <span class="input-group-append">
              <div class="input-group-text bg-transparent">
                <i class="fa fa-search pointer"></i>
              </div>
            </span>
          </div>
          <div class="col-5 input-group form-search-rounded">
            <select name="" class="form-control" id="" v-model="level">
              <option value="">Pilih jenjang</option>
              <option
                v-for="(data, index) in option_jenjang"
                :key="index"
                :value="data.id"
              >
                {{ data.name }}
              </option>
            </select>
          </div>
        </div>
      </template>

      <template v-slot:button>
        <div class="row">
          <router-link to="/user/add" v-can="'user/create'">
            <button type="button" class="btn btn-primary mr-1">
              <i class="fa fa-user-plus fa-l" aria-hidden="true"></i> Tambah User
            </button>
          </router-link>
          <!-- <a href=""></a> -->

          <a :href="get_download.url" download type="button" class="btn btn-primary mr-1">
            <i class="fa fa-download fa-l" aria-hidden="true"></i> Export
          </a>

          <!-- <button
            type="button"
            class="btn btn-primary mr-1"
            @click="download()"
            :disabled="table.dataTable.lists.length == 0"
            v-can="'user/download'"
          >
            <i class="fa fa-download fa-l" aria-hidden="true"></i> Download
          </button> -->
        </div>
      </template>

      <template v-slot:limit>
        <div class="row float-right">
          <div class="align-self-center text-right mr-1">Menampilkan</div>
          <div class="mr-1">
            <select v-model="limit" class="form-control">
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="0">Semua</option>
            </select>
          </div>
        </div>
      </template>

      <template v-slot:filter>
        <div>
          <button
            type="button"
            @click="filter = ''"
            :class="filter === '' ? 'btn-success' : 'btn-muted-trans'"
            class="btn waves-effect waves-light"
          >
            Semua User
          </button>
          <button
            type="button"
            @click="filter = 1"
            :class="filter === 1 ? 'btn-success' : 'btn-muted-trans'"
            class="btn waves-effect waves-light ml-1"
          >
            User Aktif
          </button>
          <button
            type="button"
            @click="filter = 0"
            :class="filter === 0 ? 'btn-success' : 'btn-muted-trans'"
            class="btn waves-effect waves-light ml-1"
          >
            User Nonaktif
          </button>
        </div>
      </template>

      <template v-slot:columnHeader>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nama Lengkap</th>
            <th>Jenis Kelamin</th>
            <th>Email</th>
            <th>Nomor HP</th>
            <th>Jenjang</th>
            <th>Jenis</th>
            <th>Status</th>
            <th width="10%">Aksi</th>
          </tr>
        </thead>
      </template>

      <template v-slot:action="{ data }">
        <div class="btn-action-block">
          <router-link :to="'/user/' + data.id" v-can="'user/detail'">
            <button type="button" class="btn btn-success waves-effect waves-light">
              Detail
            </button>
          </router-link>
        </div>
      </template>
    </List>
  </section>
</template>

<script>
import List from "@/components/List.vue";
import moment from "moment";

export default {
  name: "UserIndex",
  components: {
    List,
  },
  data() {
    return {
      table: {
        title: "List User",
        svgIcon: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.875 2.625C8.52397 2.625 9.15837 2.81744 9.69797 3.17799C10.2376 3.53854 10.6581 4.051 10.9065 4.65057C11.1548 5.25014 11.2198 5.90989 11.0932 6.54639C10.9666 7.18289 10.6541 7.76755 10.1952 8.22644C9.73631 8.68534 9.15164 8.99784 8.51514 9.12445C7.87864 9.25106 7.21889 9.18608 6.61932 8.93773C6.01975 8.68938 5.50729 8.26881 5.14674 7.72921C4.78619 7.18962 4.59375 6.55522 4.59375 5.90625C4.59375 5.03601 4.93945 4.20141 5.55481 3.58606C6.17016 2.9707 7.00476 2.625 7.875 2.625ZM7.875 1.3125C6.96644 1.3125 6.07829 1.58192 5.32285 2.08669C4.56741 2.59145 3.97862 3.3089 3.63093 4.1483C3.28324 4.9877 3.19227 5.91135 3.36952 6.80245C3.54677 7.69355 3.98428 8.51207 4.62673 9.15452C5.26918 9.79697 6.08771 10.2345 6.97881 10.4117C7.86991 10.589 8.79356 10.498 9.63295 10.1503C10.4724 9.80263 11.1898 9.21384 11.6946 8.4584C12.1993 7.70296 12.4688 6.81481 12.4688 5.90625C12.4688 4.68791 11.9848 3.51947 11.1233 2.65798C10.2618 1.79648 9.09334 1.3125 7.875 1.3125Z" fill="white"/>
                            <path d="M14.4375 19.0312C14.4375 19.3937 14.1437 19.6875 13.7812 19.6875C13.4188 19.6875 13.125 19.3937 13.125 19.0312V16.4062C13.125 15.536 12.7793 14.7014 12.1639 14.0861C11.5486 13.4707 10.714 13.125 9.84375 13.125H5.90625C5.03601 13.125 4.20141 13.4707 3.58606 14.0861C2.9707 14.7014 2.625 15.536 2.625 16.4062V19.0312C2.625 19.3937 2.33119 19.6875 1.96875 19.6875C1.60631 19.6875 1.3125 19.3937 1.3125 19.0312V16.4062C1.3125 15.1879 1.79648 14.0195 2.65798 13.158C3.51947 12.2965 4.68791 11.8125 5.90625 11.8125H9.84375C11.0621 11.8125 12.2305 12.2965 13.092 13.158C13.9535 14.0195 14.4375 15.1879 14.4375 16.4062V19.0312Z" fill="white"/>
                            <path d="M14.4375 3.28125C14.4375 2.91881 14.7313 2.625 15.0938 2.625H20.3438C20.7062 2.625 21 2.91881 21 3.28125C21 3.64369 20.7062 3.9375 20.3438 3.9375H15.0938C14.7313 3.9375 14.4375 3.64369 14.4375 3.28125Z" fill="white"/>
                            <path d="M14.4375 6.5625C14.4375 6.20006 14.7313 5.90625 15.0938 5.90625H20.3438C20.7062 5.90625 21 6.20006 21 6.5625C21 6.92494 20.7062 7.21875 20.3438 7.21875H15.0938C14.7313 7.21875 14.4375 6.92494 14.4375 6.5625Z" fill="white"/>
                            <path d="M14.4375 9.84375C14.4375 9.48131 14.7313 9.1875 15.0938 9.1875H18.375C18.7374 9.1875 19.0312 9.48131 19.0312 9.84375C19.0312 10.2062 18.7374 10.5 18.375 10.5H15.0937C14.7313 10.5 14.4375 10.2062 14.4375 9.84375Z" fill="white"/>
                        </svg>`,
        breadcumb: "List User",
        dataTable: {
          lists: [],
          columns: [
            "name",
            "col_jenkel",
            "email",
            "no_telp",
            "jenjang",
            "col_bill_status_html",
            "col_status",
          ],
        },
      },
      search: "",
      filter: "",
      level: "",
      limit: 10,
      option_jenjang: [],
      get_download: [],
    };
  },
  mounted() {
    let _ = this;
    _.getList();
    _.$root.$on("paginate", (data) => {
      _.getList(data);
    });
    _.get_jenjang();
    _.getDownload();
  },
  beforeDestroy() {
    this.$root.$off("paginate");
  },
  methods: {
    getList(page) {
      let _ = this;
      _.axios
        .get(
          "/student?key=" +
            _.search +
            "&status=" +
            _.filter +
            "&page=" +
            page +
            "&limit=" +
            _.limit +
            "&level=" +
            _.level
        )
        .then((resp) => {
          _.table.dataTable.lists = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getDownload() {
      let _ = this;
      _.axios
        .get("/student/export")
        .then((resp) => {
          _.get_download = resp.data;
          // console.log("LIST DOWNLOAD", _.get_download);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get_jenjang() {
      let _ = this;
      _.axios
        .get("/level/all")
        .then((resp) => {
          _.option_jenjang = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    download() {
      let _ = this;
      _.axios.defaults.responseType = "blob";

      _.axios.get("/student/export/list").then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;

        let date = moment().format("DMYhmmss");

        link.setAttribute("download", "Students-" + date + ".xls");

        document.body.appendChild(link);
        link.click();
      });
    },
  },
  watch: {
    search: function () {
      this.getList();
    },
    filter: function () {
      this.getList();
    },
    limit: function () {
      this.getList();
    },
    level: function () {
      this.getList();
    },
  },
};
</script>
