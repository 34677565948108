<template>
	<section>
        <div class="content-header" >
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item" >
                                <a style="display: inline-flex;">
                                    <i class="feather" :class="icon" ></i>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 7.18751C11.2188 4.35024 13.9334 2.90151 19.8438 2.87501C19.9382 2.87465 20.0319 2.893 20.1192 2.92899C20.2066 2.96499 20.286 3.01791 20.3528 3.08473C20.4196 3.15154 20.4725 3.23092 20.5085 3.31828C20.5445 3.40564 20.5629 3.49927 20.5625 3.59376V16.5313C20.5625 16.7219 20.4868 16.9047 20.352 17.0395C20.2172 17.1743 20.0344 17.25 19.8438 17.25C14.0938 17.25 11.8724 18.4094 10.5 20.125C9.13573 18.418 6.90626 17.25 1.15626 17.25C0.712427 17.25 0.437505 16.8884 0.437505 16.4446V3.59376C0.437149 3.49927 0.455497 3.40564 0.491491 3.31828C0.527485 3.23092 0.580414 3.15154 0.647228 3.08473C0.714041 3.01791 0.793417 2.96499 0.88078 2.92899C0.968144 2.893 1.06177 2.87465 1.15626 2.87501C7.06663 2.90151 9.78125 4.35024 10.5 7.18751Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.5 7.1875V20.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                                    Materi / Sub Materi / Konten 
                                </a>
                            </li>

                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b  >
                                   <a :href="'materi/'">

                                        <i class="feather icon-arrow-left"></i>
                                        Kembali
                                    </a>
                                      
                                 
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{ title }} </h2>
            </div>
        </div>
		<List
            :title="table.title"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan Konten">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

			<template v-slot:button>
				<div>
                    <router-link :to="'/sub/' + $route.params.id + '/konten/add'">
                        <button type="button" class="btn btn-primary">
                            Tambah Konten
                        </button>
                    </router-link>
				</div>
			</template> 

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No</th>
                        <th>Judul</th>
                        <th>Jenis Isi</th>
                        <th>Jenis Topik</th>
                        <th>Jenis</th>
                        <th>Status</th>
                        <th>Tgl. Buat</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button type="button" @click="reorder(0, data.id)" class="btn btn-primary waves-effect waves-light btn-rounded" title="Up">
                        <i class="fa fa-arrow-up"></i>
                    </button>
                    <button type="button" @click="reorder(1, data.id)" class="btn btn-danger waves-effect waves-light btn-rounded" title="Down">
                        <i class="fa fa-arrow-down"></i>
                    </button>
                    |
                    <router-link :to="'/sub/' + $route.params.id + '/konten/' + data.id" v-if="data.jenis_isi_materi == 1" title="Pilih Soal">
                        <button type="button" class="btn btn-primary waves-effect waves-light btn-rounded">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                    <router-link :to="'/sub/' + $route.params.id + '/edit/' + data.id" title="Ubah">
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded">
                            <i class="fa fa-pencil"></i>
                        </button>
                    </router-link>
                    <button type="button" @click="destroy(data)" class="btn btn-danger waves-effect waves-light btn-rounded" title="Hapus">
                        <i class="fa fa-trash"></i>
                    </button>
                    <router-link :to="'/sub/' + $route.params.id + '/konten/' + data.id + '/question'" v-if="data.jenis_isi_materi == 1" title="Lihat Soal">
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded">
                            <i class="fa fa-file"></i>
                        </button>
                    </router-link>
                    <button type="button" class="btn btn-primary waves-effect waves-light btn-rounded" v-if="data.jenis_isi_topik == 0" title="Unduh">
                        <a :href="data.col_ebook.url" target="_blank" style="color: #fff !important;"><i class="fa fa-download"></i></a>
                    </button>
                    <router-link :to="'/sub/' + $route.params.id + '/konten/' + data.id + '/diskusi'">
                        <button type="button" class="btn btn-info waves-effect waves-light btn-rounded" v-if="data.jenis_isi_materi != 1" title="Diskusi">
                            <i class="fa fa-comments"></i>
                        </button>
                    </router-link>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	components: {
		List
	},
	data() {
        
		return {
			table: {
                title: "List Konten",
                svgIcon: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 7.18751C11.2188 4.35024 13.9334 2.90151 19.8438 2.87501C19.9382 2.87465 20.0319 2.893 20.1192 2.92899C20.2066 2.96499 20.286 3.01791 20.3528 3.08473C20.4196 3.15154 20.4725 3.23092 20.5085 3.31828C20.5445 3.40564 20.5629 3.49927 20.5625 3.59376V16.5313C20.5625 16.7219 20.4868 16.9047 20.352 17.0395C20.2172 17.1743 20.0344 17.25 19.8438 17.25C14.0938 17.25 11.8724 18.4094 10.5 20.125C9.13573 18.418 6.90626 17.25 1.15626 17.25C0.712427 17.25 0.437505 16.8884 0.437505 16.4446V3.59376C0.437149 3.49927 0.455497 3.40564 0.491491 3.31828C0.527485 3.23092 0.580414 3.15154 0.647228 3.08473C0.714041 3.01791 0.793417 2.96499 0.88078 2.92899C0.968144 2.893 1.06177 2.87465 1.15626 2.87501C7.06663 2.90151 9.78125 4.35024 10.5 7.18751Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.5 7.1875V20.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
				breadcumb: "Materi / Sub Materi / Konten",
                dataTable: {
                    lists: [],
                    datas: [],
                    columns: ['judul_topik', 'col_jenis_isi_materi', 'col_jenis_isi_topik', 'col_jenis', 'col_status', 'created_at']
                }
			},
            search: '',
            limit: 10,
         
		}
        
	},
    mounted() {
        let _ = this
        _.getList(),
       
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
      
        getList(page) {
            let _ = this
            _.axios.get('/sub_category/chapter/content?id_subject_chapter='+ _.$route.params.id +'&key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                    _.table.dataTable.datas = resp.data.data.rows.data
                    console.log(_.table.dataTable.datas)
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Konten?',                
                html:   '<p class="popup-title">'+ x.judul_topik + '</p>' +
                        '<p class="popup-text-normal">Konten yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Konten',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/sub_category/chapter/content', { data: { id: x.id } })
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        reorder($dir, $id_subject_content){
            let _ = this
            let $data = {
                id_subject_content: $id_subject_content,
                direction: $dir
            };

            _.axios.post('/sub_category/chapter/content/reorder', $data)
                .then(resp=>{
                    if (resp.data.status){
                        _.$toast.success(resp.data.message)
                        _.getList()
                    } else {
                        _.$toast.error(resp.data.message)
                    }                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        limit: function() {
            this.getList()
        }
    }
}
</script>