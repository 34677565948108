<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :icon="table.icon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

            <template v-slot:limit2>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Kode Pelajaran</th>
                        <th>Pelajaran</th>
                        <th>Jumlah Soal</th>
                        <th>Tgl. Buat</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="$route.params.id_sub + '/course/' + data.id">
                        <button type="button" class="btn btn-primary waves-effect waves-light btn-rounded">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'TryoutIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Data Pelajaran Subkategori Soal Try Out",
                icon: "icon-file-text",
				breadcumb: "List Try Out / Management Sub Try Out",
                dataTable: {
                    lists: [],
                    columns: ['kode', 'name', 'col_jml_question', 'created_at']
                }
            },
            search: '',
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/course?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        limit: function() {
            this.getList()
        }
    }
}
</script>