<template>
    <section>
        <List :title="table.title" :breadcumb="table.breadcumb" :dataTable="table.dataTable" :svgIcon="table.svgIcon">
            <template v-slot:search>
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search"
                            id="example-search-input" placeholder="Masukkan ID Kuis">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <a href="#"><i class="fa fa-search"></i></a>
                            </div>
                        </span>
                    </div>
                    <div class="col-3 input-group form-search-rounded">
                        <select name="" class="form-control" id="" v-model="filter.materi">
                            <option value="">Pilih materi</option>
                            <option v-for="(data, index) in option_materi" :key="index" :value="data.id">{{ data.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-4 input-group form-search-rounded">
                        <select name="" class="form-control" id="" v-model="filter.pelajaran">
                            <option value="">Pilih mata pelajaran</option>
                            <option v-for="(data, index) in option_pelajaran" :key="index" :value="data.id">{{ data.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </template>

            <template v-slot:button>
                <div>
                    <router-link to="/quiz/add" v-can="'quiz/create'">
                        <button type="button" class="btn btn-primary">
                            Tambah Kuis
                        </button>
                    </router-link>
                </div>
            </template>

            <template v-slot:limit>
                <div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
                </div>
            </template>

            <template v-slot:filter>
                <div>
                    <button type="button" @click="filter.jenis = ''"
                        :class="filter.jenis === '' ? 'btn-primary' : 'btn-muted-trans'"
                        class="btn waves-effect waves-light">
                        Semua
                    </button>
                    <button type="button" @click="filter.jenis = 1"
                        :class="filter.jenis === 1 ? 'btn-primary' : 'btn-muted-trans'"
                        class="btn waves-effect waves-light ml-1">
                        Premium
                    </button>
                    <button type="button" @click="filter.jenis = 0"
                        :class="filter.jenis === 0 ? 'btn-primary' : 'btn-muted-trans'"
                        class="btn waves-effect waves-light ml-1">
                        Free User
                    </button>
                </div>
            </template>

            <template v-slot:columnHeader>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nama Kuis</th>
                        <th>Jumlah Soal</th>
                        <th>Waktu Pengerjaan</th>
                        <th>Jenis Kuis</th>
                        <th>Status</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button type="button" @click="reorder(0, data.id)"
                        class="btn btn-primary waves-effect waves-light btn-rounded" title="Up">
                        <i class="fa fa-arrow-up"></i>
                    </button>
                    <button type="button" @click="reorder(1, data.id)"
                        class="btn btn-danger waves-effect waves-light btn-rounded" title="Down">
                        <i class="fa fa-arrow-down"></i>
                    </button>
                    |
                    <router-link :to="'quiz/' + data.id" v-can="'quiz/detail'">
                        <button type="button" class="btn btn-primary waves-effect waves-light btn-rounded"
                            title="Lihat Detail">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                    <router-link :to="'quiz/edit/' + data.id" v-can="'quiz/update'">
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded" title="Edit">
                            <i class="fa fa-pencil"></i>
                        </button>
                    </router-link>
                    <button type="button" @click="copy(data)" class="btn btn-secondary waves-effect waves-light btn-rounded"
                        v-can="'tryout/duplicate'" title="Duplikat">
                        <i class="fa fa-copy"></i>
                    </button>
                    <router-link :to="'quiz/' + data.id + '/questions'" v-can="'quiz/view soal'">
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded"
                            title="Lihat Soal">
                            <i class="fa fa-file"></i>
                        </button>
                    </router-link>
                    <router-link :to="'quiz/' + data.id + '/students'" v-can="'quiz/view student'">
                        <button type="button" class="btn btn-primary waves-effect waves-light btn-rounded"
                            title="Lihat Pendaftar">
                            <i class="fa fa-user"></i>
                        </button>
                    </router-link>
                    <button type="button" @click="destroy(data)" class="btn btn-danger waves-effect waves-light btn-rounded"
                        v-can="'quiz/delete'" title="Hapus">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
        </List>
    </section>
</template>

<script>
import List from '@/components/List.vue'

export default {
    name: 'KuisIndex',
    components: {
        List
    },
    data() {
        return {
            table: {
                title: "List Kuis",
                svgIcon: `<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.334 3.83325H17.2507C17.759 3.83325 18.2465 4.03519 18.6059 4.39463C18.9654 4.75408 19.1673 5.24159 19.1673 5.74992V19.1666C19.1673 19.6749 18.9654 20.1624 18.6059 20.5219C18.2465 20.8813 17.759 21.0833 17.2507 21.0833H5.75065C5.24232 21.0833 4.75481 20.8813 4.39536 20.5219C4.03592 20.1624 3.83398 19.6749 3.83398 19.1666V5.74992C3.83398 5.24159 4.03592 4.75408 4.39536 4.39463C4.75481 4.03519 5.24232 3.83325 5.75065 3.83325H7.66732" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 9.5835H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 13.4167H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 17.25H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 9.5835H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 13.4167H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 17.25H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.3743 1.91675H8.62435C8.09508 1.91675 7.66602 2.34581 7.66602 2.87508V4.79175C7.66602 5.32102 8.09508 5.75008 8.62435 5.75008H14.3743C14.9036 5.75008 15.3327 5.32102 15.3327 4.79175V2.87508C15.3327 2.34581 14.9036 1.91675 14.3743 1.91675Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
                breadcumb: "List Kuis",
                dataTable: {
                    lists: [],
                    columns: ['name', 'col_jum_soal', 'col_waktu', 'col_jenis_html', 'col_status_html']
                }
            },
            search: '',
            filter: {
                jenis: '',
                pelajaran: '',
                materi: ''
            },
            option_pelajaran: [],
            option_materi: [],
            limit: 10
        }
    },
    mounted() {
        let _ = this
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.getList()
        _.get_pelajaran()
        _.get_materi()
    },
  
    beforeDestroy() {
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/quiz?key=' + _.search + '&jenis=' + _.filter.jenis + '&id_theory=' + _.filter.materi + '&id_course=' + _.filter.pelajaran + '&page=' + page + '&limit=' + _.limit)
                .then(resp => {
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err => {
                    console.log(err.response)
        })
        },
        get_pelajaran() {
            let _ = this
            _.axios.get('/course/all')
                .then(resp => {
                    _.option_pelajaran = resp.data.data.rows
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        get_materi() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp => {
                    _.option_materi = resp.data.data.rows
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Kuis?',
                html: '<p class="popup-title">' + x.name + '</p>' +
                    '<p class="popup-text-muted">' + x.col_jum_soal + ' Soal</p>' +
                    '<p class="popup-text-muted">Waktu - ' + x.waktu + ' Menit</p>' +
                    '<p class="popup-text-normal">Kuis yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Kuis',
            }).then((result) => {
                if (result.value) {
                    _.axios.delete('/quiz', { data: { id: x.id } })
                        .then(resp => {
                            if (resp.status) {
                                _.getList()
                                _.$swal.fire({
                                    title: 'Terhapus!',
                                    text: "Data berhasil dihapus",
                                    icon: 'success',
                                    confirmButtonColor: '#37BA71',
                                    confirmButtonText: 'OK'
                                })
                            } else {
                                console.log("Failed")
                            }
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                }
            })
        },
        reorder($dir, $id_quiz) {
            let _ = this
            let $data = {
                id_quiz: $id_quiz,
                direction: $dir
            };

            _.axios.post('/quiz/reorder', $data)
                .then(resp => {
                    if (resp.data.status) {
                        _.$toast.success(resp.data.message)
                        _.getList()
                    } else {
                        _.$toast.error(resp.data.message)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        copy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Duplikat Quiz',
                input: 'text',
                inputValue: x.name,
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#79d192',
                confirmButtonText: 'Simpan',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Nama tidak boleh kosong!'
                    } else if (value == x.name) {
                        return 'Nama tidak boleh sama!'
                    }
                }
            }).then((result) => {
                if (result.value) {
                    let data = {
                        id_quiz: x.id,
                        name: result.value
                    }

                    _.axios.post('/quiz/duplicate', data)
                        .then(resp => {
                            if (resp.status) {
                                _.getList()
                                _.$swal.fire({
                                    title: 'Berhasil Duplikasi!',
                                    text: "Data berhasil diduplikat",
                                    icon: 'success',
                                    confirmButtonColor: '#37BA71',
                                    confirmButtonText: 'OK'
                                })
                            } else {
                                console.log("Failed")
                            }
                        })
                        .catch(err => {
                            _.$swal.fire({
                                title: 'Gagal Duplikasi!',
                                text: err.response.data.message,
                                icon: 'error',
                                confirmButtonColor: '#37BA71',
                                confirmButtonText: 'OK'
                            })
                        })
                }
            })
        }
    },
    watch: {
        search: function () {
            this.getList()
        },
        'filter.jenis': function () {
            this.getList()
        },
        'filter.pelajaran': function () {
            this.getList()
        },
        'filter.materi': function () {
            this.getList()
        },
        limit: function () {
            this.getList()
        }
    }
}
</script>