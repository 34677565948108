<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 7.18751C11.2188 4.35024 13.9334 2.90151 19.8438 2.87501C19.9382 2.87465 20.0319 2.893 20.1192 2.92899C20.2066 2.96499 20.286 3.01791 20.3528 3.08473C20.4196 3.15154 20.4725 3.23092 20.5085 3.31828C20.5445 3.40564 20.5629 3.49927 20.5625 3.59376V16.5313C20.5625 16.7219 20.4868 16.9047 20.352 17.0395C20.2172 17.1743 20.0344 17.25 19.8438 17.25C14.0938 17.25 11.8724 18.4094 10.5 20.125C9.13573 18.418 6.90626 17.25 1.15626 17.25C0.712427 17.25 0.437505 16.8884 0.437505 16.4446V3.59376C0.437149 3.49927 0.455497 3.40564 0.491491 3.31828C0.527485 3.23092 0.580414 3.15154 0.647228 3.08473C0.714041 3.01791 0.793417 2.96499 0.88078 2.92899C0.968144 2.893 1.06177 2.87465 1.15626 2.87501C7.06663 2.90151 9.78125 4.35024 10.5 7.18751Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.5 7.1875V20.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    List Pelajaran / {{$route.params.id_soal ? 'Edit' : 'Tambah'}} Soal
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link :to="'/bank_soal/' + $route.params.id">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{ $route.params.id_soal ? 'Edit' : 'Tambah'}} Soal</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <ValidationProvider name="Pertanyaan" v-slot="{ errors }" rules="required">
                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Pertanyaan</label>
                                                    <quill-editor v-model="model.soal" :options="editorOption"/>
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Jawaban A" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jawaban A</label>
                                                            <quill-editor v-model="model.jawaban_a.name" :options="editorOption_min"/>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Bobot A" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Bobot A</label>
                                                            <input type="number" v-model="model.jawaban_a.bobot" class="form-control"
                                                                placeholder="Masukkan bobot A">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Jawaban B" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jawaban B</label>
                                                            <quill-editor v-model="model.jawaban_b.name" :options="editorOption_min"/>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Bobot B" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Bobot B</label>
                                                            <input type="number" v-model="model.jawaban_b.bobot" class="form-control"
                                                                placeholder="Masukkan bobot B">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Jawaban C" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jawaban C</label>
                                                            <quill-editor v-model="model.jawaban_c.name" :options="editorOption_min"/>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Bobot C" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Bobot C</label>
                                                            <input type="number" v-model="model.jawaban_c.bobot" class="form-control"
                                                                placeholder="Masukkan bobot C">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Jawaban D" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jawaban D</label>
                                                            <quill-editor v-model="model.jawaban_d.name" :options="editorOption_min"/>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Bobot D" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Bobot D</label>
                                                            <input type="number" v-model="model.jawaban_d.bobot" class="form-control"
                                                                placeholder="Masukkan bobot D">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                            </div> 
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Jawaban E" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jawaban E</label>
                                                            <quill-editor v-model="model.jawaban_e.name" :options="editorOption_min"/>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Bobot E" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Bobot E</label>
                                                            <input type="number" v-model="model.jawaban_e.bobot" class="form-control"
                                                                placeholder="Masukkan bobot E">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                            </div>   
                                            <ValidationProvider name="Kunci jawaban" v-slot="{ errors }" rules="required">
                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Kunci Jawaban</label>
                                                    <select name="" id="" v-model="kunjaw" class="form-control">
                                                        <option value="">Pilih Jawaban</option>                                                            
                                                        <option value="0">A</option>
                                                        <option value="1">B</option>
                                                        <option value="2">C</option>
                                                        <option value="3">D</option>
                                                        <option value="4">E</option>
                                                    </select>
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider> 
                                            <ValidationProvider name="Pembahasan" v-slot="{ errors }" rules="required">
                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Pembahasan</label>
                                                    <quill-editor v-model="model.pembahasan" :options="editorOption"/>
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>                                                                                                             
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary float-right" :disabled="invalid">
                                    Simpan
                                </button>
                                <router-link :to="'/bank_soal'">
                                    <button type="button" class="btn btn-outline-success mr-1 float-right">
                                        Cancel
                                    </button>
                                </router-link>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
import { Quill } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue';
Quill.register('modules/imageResize', ImageResize);

export default {
	name: 'BankSoalFormAdd',
    data() {
        return {            
            model: {
                id_course: this.$route.params.id,
                soal: '',
                jawaban_a: {
                    name: '',
                    bobot: 0,
                    status: false
                },
                jawaban_b: {
                    name: '',
                    bobot: 0,
                    status: false
                },
                jawaban_c: {
                    name: '',
                    bobot: 0,
                    status: false
                },
                jawaban_d: {
                    name: '',
                    bobot: 0,
                    status: false
                },
                jawaban_e: {
                    name: '',
                    bobot: 0,
                    status: false
                },
                pembahasan: ''
            },
            kunjaw: '',
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'font': [] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    imageResize: {
                        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
                        handleStyles: {
                        backgroundColor: 'black',
                        border: 'none',
                        color: 'white'
                        // other camelCase styles for size display
                        },
                        toolbarStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white'
                            // other camelCase styles for size display
                        },
                        toolbarButtonStyles: {
                            // ...
                        },
                        toolbarButtonSvgStyles: {
                            // ...
                        },
                    },
                }
            },
            editorOption_min: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        ['image']
                    ],
                    imageResize: {
                        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
                        handleStyles: {
                        backgroundColor: 'black',
                        border: 'none',
                        color: 'white'
                        // other camelCase styles for size display
                        },
                        toolbarStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white'
                            // other camelCase styles for size display
                        },
                        toolbarButtonStyles: {
                            // ...
                        },
                        toolbarButtonSvgStyles: {
                            // ...
                        },
                    },
                }
            },
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/course/question?id=' + _.$route.params.id_soal)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    _.model.jawaban_a = JSON.parse(_.model.jawaban_a)
                    _.model.jawaban_b = JSON.parse(_.model.jawaban_b)
                    _.model.jawaban_c = JSON.parse(_.model.jawaban_c)
                    _.model.jawaban_d = JSON.parse(_.model.jawaban_d)
                    _.model.jawaban_e = JSON.parse(_.model.jawaban_e)

                    if (_.model.jawaban_a.status == true){
                        _.kunjaw = "0"
                    } else if (_.model.jawaban_b.status == true){
                        _.kunjaw = "1"
                    } else if (_.model.jawaban_c.status == true){
                        _.kunjaw = "2"
                    } else if (_.model.jawaban_d.status == true){
                        _.kunjaw = "3"
                    } else {
                        _.kunjaw = "4"
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            if (_.$route.params.id_soal){
                _.axios.post('/course/question/'+_.$route.params.id_soal, _.model)
                    .then(resp => {                
                        _.$router.push('/bank_soal/' + _.$route.params.id)    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.post('/course/question', _.model)
                    .then(resp => {                
                        _.$router.push('/bank_soal/' + _.$route.params.id)    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            }            
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id_soal) {
            _.get()
        }
    },
    watch: {
        kunjaw: function(){
            let _ = this
            _.model.jawaban_a.status = false
            _.model.jawaban_b.status = false
            _.model.jawaban_c.status = false
            _.model.jawaban_d.status = false
            _.model.jawaban_e.status = false

            switch (_.kunjaw) {
                case "0":
                    _.model.jawaban_a.status = true;
                    break;
                case "1":
                    _.model.jawaban_b.status = true;
                    break;
                case "2":
                    _.model.jawaban_c.status = true;
                    break;
                case "3":
                    _.model.jawaban_d.status = true;
                    break;
                case "4":
                    _.model.jawaban_e.status = true;
                    break;
            
                default:
                    break;
            }
        }
    }
}
</script>