<template>
  <section>

    <div class="content-body">
      <List
      :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon"
      >
        <template v-slot:search>
          <div class="row">
            <div class="col-5 input-group form-search-rounded">
              <input
                class="form-control py-2 border-right-0 border"
                v-model.lazy="search_artikel"
                type="search"
                id="example-search-input"
                placeholder="Masukkan Judul Artikel"
              />
              <span class="input-group-append">
                <div class="input-group-text bg-transparent">
                  <i class="fa fa-search pointer"></i>
                </div>
              </span>
            </div>
          </div>
        </template>

        <template v-slot:button>
          <div>
            <router-link to="/add_artikel" v-can="'package/create'">
              <button type="button" class="btn btn-primary">
                Tambah Artikel
              </button>
            </router-link>
          </div>
        </template>

        <template v-slot:limit>
          <div class="row float-right">
            <div class="align-self-center text-right mr-1">Menampilkan</div>
            <div class="mr-1">
              <select v-model="limit" class="form-control">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="0">Semua</option>
              </select>
            </div>
          </div>
        </template>

        <template v-slot:columnHeader>
          <thead>
            <tr>
              <th>No</th>
              <th>Judul</th>
              <th>Foto</th>
             <th>Deskripsi</th>

              <th width="10%">Aksi</th>
            </tr>
          </thead>
        </template>

        <template v-slot:action="{ data }">
          <div class="btn-action-block">
            <router-link
              :to="'edit_artikel/' + data.id"
              
            >
              <button
                type="button"
                class="btn btn-warning waves-effect waves-light btn-rounded"
              >
                <i class="fa fa-pencil"></i>
              </button>
            </router-link>
            <button
              type="button"
              @click="destroy(data)"
              class="btn btn-danger waves-effect waves-light btn-rounded"
              v-can="'package/delete'"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </template>
      </List>
    </div>
    <!-- <List /> -->
  </section>
</template>
<script>
import List from "@/components/List.vue";
export default {
  name: "Artikel",
  components: {
    List,
  },
  data() {
    return {
      table: {
        title: "List Artikel",
        svgIcon: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.41978 14.6H17.3994V15.4H5.99979C5.23122 15.4 4.59986 14.7686 4.59986 14C4.59986 13.7529 4.66338 13.5257 4.7708 13.3377L4.77087 13.3377L4.77536 13.3296L6.12531 10.8796L6.27358 10.6105L6.14192 10.3329L2.54205 2.74288L2.37942 2.4H1.99993H0.6V1.6H2.88892L3.66684 3.25521L3.82888 3.6H4.20986H18.9985C19.068 3.60026 19.1363 3.61849 19.1967 3.65293C19.2572 3.68746 19.3078 3.73711 19.3434 3.79703C19.379 3.85694 19.3985 3.92507 19.3999 3.99477C19.4013 4.0638 19.385 4.13202 19.3525 4.1929C19.3521 4.19349 19.3518 4.19408 19.3515 4.19466L15.7741 10.6802L15.7733 10.6815C15.5369 11.1127 15.0767 11.4 14.5495 11.4H7.09976H6.74565L6.5745 11.71L5.67453 13.34L5.63669 13.4085L5.6177 13.4845L5.5877 13.6045L5.56979 13.6761V13.75C5.56979 14.2214 5.94839 14.6 6.41978 14.6ZM4.60986 19C4.60986 18.2274 5.23513 17.6 5.99979 17.6C6.76837 17.6 7.39973 18.2314 7.39973 19C7.39973 19.7686 6.76837 20.4 5.99979 20.4C5.23513 20.4 4.60986 19.7726 4.60986 19ZM14.6095 19C14.6095 18.2274 15.2348 17.6 15.9995 17.6C16.768 17.6 17.3994 18.2314 17.3994 19C17.3994 19.7686 16.768 20.4 15.9995 20.4C15.2348 20.4 14.6095 19.7726 14.6095 19Z" stroke="white" stroke-width="1.2"/>
                        </svg>`,
        breadcumb: "List Artikel",
        dataTable: {
          lists: [],
          columns: ["judul", "foto", "deskripsi"],
        },
      },
      limit: 10,
      search_artikel: "",
    };
  },
  methods: {
    get_artikel(page) {
      let _ = this;
      
      _.axios
        .get("/artikel?key=" + _.search_artikel  + '&page=' + page)
        .then((resp) => {
            _.table.dataTable.lists = resp.data.data.rows;
       
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Artikel?',                
                html:   '<p class="popup-title">'+ x.judul + '</p>' +
                        '<p class="popup-text-normal">Artikel yang sudah dihapus tidak dapat dikembalikan </p>' ,

                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Artikel',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/artikel', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
  },
  mounted() {
    let _ = this;
    _.get_artikel()
        _.$root.$on("paginate", (data) => {
            _.get_artikel(data)
        });
  },
  beforeDestroy(){
        this.$root.$off("paginate");
    },

  watch: {
    search_artikel: function () {
      this.get_artikel();
    },
    limit: function() {
            this.get_artikel()
        }
  },
};
</script>
