<template>
	<section>
		<List
            :title="table.title"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">            

            
            
            <template v-slot:customHeader>
                <div class="content-header">
                    <div class="content-header-left mb-2">
                        <div class="breadcrumbs-top">                    
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">                                
                                        <a style="display: inline-flex;">
                                            <div v-html="table.svgIcon" v-if="table.svgIcon != null"></div>
                                            {{ table.breadcumb }}
                                        </a>
                                    </li>
                                    
                                </ol>
                            </div>
                        </div>
                        <div class="breadcrumbs-top">                    
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <b>
                                            <a type="button" data-dismiss="modal" aria-label="Close">
                                                <i class="feather icon-arrow-left"></i> 
                                                Kembali
                                            </a>
                                            
                                        </b>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <h2 class="content-header-title mb-0">{{ table.title }} </h2>
                    </div>
                </div>
            </template>
            <template v-slot:limit>
                
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Judul</th>
                        <th>Type</th>
                        <th>Deskripsi</th>
                        <th width="10%">Harga</th>
                        <th>Tgl. Buat</th>
                        <th>Status</th>
                        <th>Gabung Waktu</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block"> 
                    <button type="button" v-if="id.indexOf(data.id) == -1" @click="choose(data.id)" class="btn btn-primary waves-effect waves-light">
                        Pilih
                    </button>
                    <button type="button" v-else @click="unselect(data.id)" class="btn btn-danger waves-effect waves-light">
                        Batal
                    </button>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'TryoutIndex',
    props: ['id'],
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Try Out",
                svgIcon: `<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.375 1H2.875C2.37772 1 1.90081 1.19754 1.54917 1.54917C1.19754 1.90081 1 2.37772 1 2.875V17.875C1 18.3723 1.19754 18.8492 1.54917 19.2008C1.90081 19.5525 2.37772 19.75 2.875 19.75H14.125C14.6223 19.75 15.0992 19.5525 15.4508 19.2008C15.8025 18.8492 16 18.3723 16 17.875V6.625L10.375 1Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.375 1V6.625H16" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.25 11.3125H4.75" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.25 15.0625H4.75" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.625 7.5625H5.6875H4.75" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
				breadcumb: "List Try Out",
                dataTable: {
                    lists: [],
                    columns: ['name', 'col_level', 'deskripsi', 'harga_rupiah', 'created_at', 'col_status_publik', 'col_status_gabung_waktu']
                }
			},
            search: '',
            filter: {
                jenjang: '',
                materi: ''
            },
            option_jenjang: [],
            option_materi: [],
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/tryout?key=' + _.search + '&page=' + page + '&id_level=' + _.filter.jenjang + '&id_theory=' + _.filter.materi + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })       
        },
        choose(data){
            let _ = this
            _.$root.$emit('pickTryout', data);
            _.$root.$emit('pick', data);
        },
        unselect(data){
            let _ = this
            _.$root.$emit('unpickTryout', data);
            _.$root.$emit('unpick', data);
        }
    },
    watch: {
        limit: function() {
            this.getList()
        }
    }
}
</script>